<template>
  <div class="row content-row mt-4" v-for="result in results" :key="result.position">
    <div class="col-12 result-col">
      <EngineCard :result="result" />
    </div>
  </div>
</template>

<script>
import EngineCard from '@/components/results/EngineCard';
import { enginesEn, enginesFr } from '@/utils/data/engines';

export default {
  name: 'FullResults',
  components: {
    EngineCard,
  },
  data() {
    return {
      engines: [],
      results: [],
      lang: 'en',
      loading: true,
      showError: false,
    };
  },
  created() {
    this.lang = this.$i18n.locale;
    this.engines = this.lang === 'en' ? enginesEn : enginesFr;

    const res = localStorage.getItem('results');
    if (res) {
      const scores = JSON.parse(res);
      this.filterResults(scores);
      this.loading = false;
      return;
    }

    this.showError = true;
    this.loading = false;
  },
  methods: {
    filterResults(scores) {
      const engines = this.engines;
      const data = [];
      
      for (let i in scores) {
        const res = engines.find((engine) => engine.name === scores[i].name);
        data.push({
          ...scores[i],
          ...res,
          position: parseInt(i) + 1,
        });
      }

      this.results = data;
      this.showError = data.length !== 0 ? false : true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.result-col {
  margin-bottom: 3rem;
}
</style>