<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="d-flex">
        <h4>
          <span v-if="result.position">{{ result.position }}. </span
          >{{ $t(`select.${result.id}`) }}
        </h4>
        
      </div>
    </div>

    <div class="card-body mt-4">
      <div class="mb-4">
        <h4 class="mb-4 text-center">{{ $t('indicators.global') }}</h4>
      </div>
      <div class="dash-score mb-2">
        <AppDashScore :score="result.score" :stars="stars" />
      </div>
      <p class="global-score-description">{{ $t('indicators.global_score')}}</p>
      <Indicators :indicators="result.indicators" />
    </div>
  </div>
  <InfoModal />
</template>

<script>
import Indicators from '@/components/engines/Indicators';
import InfoModal from '@/components/engines/InfoModal';
export default {
  name: 'EngineCard',
  props: ['result'],
  components: {
    InfoModal,
    Indicators,
  },
  data() {
    return {
      activeItem: 'Overall',
      stars: null,
    };
  },
  created() {
    this.stars = this.result.stars;
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 0;
  transition: all 0.2s ease-in-out;
  border: none;
  border-radius: 0;
  .card-header {
    padding-left: 2rem;
    background-color: $light;
    color: $primary;
    border-bottom: 1px solid $primary;
    .d-flex {
      align-items: center !important;
      justify-content: space-between;
      padding-top: 1rem;
      .bi-caret-down-fill {
        font-size: 25px;
        cursor: pointer;
      }
    }
  }
  .card-body {
    padding: 1rem 2rem;
    transition: all 0.3s ease;
  }
}
</style>
