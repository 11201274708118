<template>
  <div class="container-fluid banner">
    <div class="row text-center">
      <div class="banner-text">
        <h2 classs="mb-4">{{ $t('results.title') }}</h2>
        <p>
          {{ $t('results.description') }}
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <FullResults />
  </div>
</template>

<script>
import FullResults from '@/components/results/FullResults';
export default {
  name: 'Results',
  components: {
    FullResults,
  },
};
</script>

<style lang="scss">
.results-title {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
</style>
